<template>
  <div id="pwd_page" class="login-page">
    <div class="login-bg">
      <div class="login-bg-shadow">
        <div class="login-bg-shadow-mask"></div>
      </div>
      <img src="../../assets/images/logo_login.png" />
    </div>

    <div class="login-main">
      <div class="login-container pwd-container" id="pwd_box" v-loading="isLoading">
        <div class="login-title">
          <!-- <img src="../../assets/images/icon/icon_user.png" alt="" /> -->
          <span class="title">忘记密码</span>
        </div>
        <div class="login-content">
          <el-form :model="data" :rules="rules" ref="data" label-position="left">
            <el-form-item prop="phoneNumber">
              <el-input v-model="data.phoneNumber" type="text" placeholder="请输入手机号" @keyup.enter.native="submitForm()">
              </el-input>
            </el-form-item>
            <el-form-item prop="smscode" class="sms-form-item">
              <el-input v-model="data.smscode" placeholder="验证码" @keyup.enter.native="submitForm()"></el-input>
              <span class="sms" @click="handleGetSms" :class="{ forbid: isSend }">
                {{ buttonTxt }}
                <span v-show="isSend && times != 0">({{ times }})s </span>
              </span>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="data.password" placeholder="输入新密码" type="password" show-password
                @keyup.enter.native="submitForm()"></el-input>
            </el-form-item>
            <el-button type="primary" :disabled="isSubmit" class="btn btn-success btn-block" @click="submitForm()">重置密码
            </el-button>
            <div class="pwd-footer">
              <span @click="$router.push('/login')">返回登录></span>
            </div>
          </el-form>
        </div>
        <div v-if="sysMsg" class="err-msg">{{ sysMsg }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { isPhone } from "@/libs/checker.js";
import { validatePhone } from "@/libs/elementChecker.js";
import { hex_sha1 } from "@/libs/sha1.js";
export default {
  name: "login",
  data() {
    return {
      isSubmit: false,
      data: {
        phoneNumber: "",
        password: "",
        smscode: "",
      },
      rules: {
        phoneNumber: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhone,
          },
        ],
        smscode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
      },
      sysMsg: "",
      SUCCESS_CODE: 0,
      isLoading: false,
      buttonTxt: "获取验证码",
      timer: null,
      times: "", // 计时
      isSend: false, // 是否发送了短信校验码
    };
  },
  mounted() { },
  methods: {
    // 获取短信验证码
    handleGetSms() {
      let self = this;
      if (self.isSend) {
        return;
      }
      self.$refs.data.validateField("phoneNumber", function (error) {
        // console.log(error);
        if (error) {
          return;
        } else {
          self.isSend = true;
          let params = {
            tel: self.data.phoneNumber,
            type: 3,
          };
          self.$http
            .post("/sys-user/getCheckCode", null, { params })
            .then((res) => {
              if (res.code == self.SUCCESS_CODE) {
                self.$message.success("短信发送成功！");
                self.countTime(60);
              } else {
                self.buttonTxt = "获取验证码";
                self.isSend = false;
                self.times = 0;
                self.$message.error(res.msg);
              }
            })
            .catch((err) => {
              self.buttonTxt = "获取验证码";
              self.isSend = false;
              self.times = 0;
              self.$message.error("获取失败，" + err);
            });
        }
      });
    },
    // 获取验证码，倒计时
    countTime(time) {
      let self = this;
      self.buttonTxt = "重新获取";
      self.times = Number(time);
      if (self.timer) {
        clearInterval(self.timer);
      }
      self.timer = setInterval(function () {
        self.times--;
        if (self.times == 0) {
          clearInterval(self.timer);
          self.buttonTxt = "获取验证码";
          self.isSend = false;
        }
      }, 1000);
    },
    // 重置密码
    resetPwd() {
      let params = {
        tel: this.data.phoneNumber,
        code: this.data.smscode,
        password: hex_sha1(this.data.password),
      };
      this.$http
        .post("/sys-user/resetPwd", null, { params })
        .then((res) => {
          console.log(res);
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(`${res.msg},请登录！`);
            // 跳转到首页
            this.$router.push("/login");
          } else {
            this.$message.error(`重置失败，${res.msg}`);
          }
          this.isSubmit = false;
          this.isLoading = false;
        })
        .catch((err) => {
          this.$message.error(`重置失败，${err}`);
          this.isSubmit = false;
          this.isLoading = false;
        });
    },
    // 提交登录表单--登录
    submitForm() {
      this.$refs["data"].validate((valid) => {
        if (valid) {
          this.resetPwd();
        } else {
          return false;
        }
      });
    },
  },
};
</script>
